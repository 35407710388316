import {
    DELETE_OBJECTS_COMMIT, DELETE_OBJECTS_ROLLBACK,
    DELETE_OBJECTS_SEND,
    FETCH_OBJECTS_SUCCESS,
    PATCH_OBJECT_SEND,
    PATCH_OBJECT_SEND_COMMIT,
    PATCH_OBJECT_SEND_ROLLBACK,
    POST_OBJECT_SEND
} from "../actions/objects.action";

const initState = {
    objects: []
}

const objectsReducer = (state = initState, action) => {
    switch (action.type) {
        case FETCH_OBJECTS_SUCCESS:
            return {
                ...state,
                objects: action.payload
            }
        case PATCH_OBJECT_SEND:
            return {
                objects: action.meta.offline.effect.meta.objects
            }
        case POST_OBJECT_SEND:
            return {
                objects: action.meta.offline.effect.meta.objects
            }
        case PATCH_OBJECT_SEND_COMMIT:
            return state
        case PATCH_OBJECT_SEND_ROLLBACK:
            return state
        case DELETE_OBJECTS_SEND:
            return {
                objects: action.meta.offline.effect.meta.objects
            }
        case DELETE_OBJECTS_COMMIT:
            return state
        case DELETE_OBJECTS_ROLLBACK:
            return state
        default:
            return state
    }
}

export default objectsReducer
