import {
    FETCH_OBJECT_COMPONENTS_SUCCESS,
    POST_OBJECT_COMPONENT_SEND,
    PATCH_OBJECT_COMPONENT_SEND,
    DELETE_OBJECT_COMPONENTS_ROLLBACK,
    DELETE_OBJECT_COMPONENTS_SEND,
    DELETE_OBJECT_COMPONENTS_COMMIT
} from "../actions/object.components.action";

const initState = {
    objectComponents: []
}

const objectComponentsReducer = (state = initState, action) => {
    switch (action.type) {
        case FETCH_OBJECT_COMPONENTS_SUCCESS:
            return {
                ...state,
                objectComponents: action.payload
            }
        case PATCH_OBJECT_COMPONENT_SEND:
            return {
                objectComponents: action.meta.offline.effect.meta.objectComponents
            }
        case POST_OBJECT_COMPONENT_SEND:
            return {
                objectComponents: action.meta.offline.effect.meta.objectComponents
            }
        case DELETE_OBJECT_COMPONENTS_SEND:
            return {
                objectComponents: action.meta.offline.effect.meta.objectComponents
            }
        case DELETE_OBJECT_COMPONENTS_COMMIT:
            return state
        case DELETE_OBJECT_COMPONENTS_ROLLBACK:
            return state
        default:
            return state
    }
}

export default objectComponentsReducer
