import {FETCH_WORK_SECTIONS_SUCCESS} from "../actions/worksections.action";

const initState = {
    workSections: []
}

const workSectionsReducer = (state = initState, action) => {
    switch (action.type) {
        case FETCH_WORK_SECTIONS_SUCCESS:
            return {
                workSections: action.payload
            }
        default:
            return state
    }
}

export default workSectionsReducer