import {applyMiddleware, compose, createStore} from "redux";
import {persistReducer, persistStore} from 'redux-persist'
import {default as thunkMiddleware} from "redux-thunk";
import {createOffline} from '@redux-offline/redux-offline';
import offlineConfig from '@redux-offline/redux-offline/lib/defaults';
import defaultQueue from '@redux-offline/redux-offline/lib/defaults/queue';
import rootReducer from "../reducers";
import {discardRequest} from "./discard.request";
import {retryRequest} from "./retry.request";
import getAuthHeader from "../../authentication/auth.header";
import getJwtToken from "../../authentication/jwt.token";
import {fetchInitData} from "./init.data";
import {OFFLINE_STATUS_CHANGED} from "@redux-offline/redux-offline/lib/constants";
import localforage from 'localforage';

let persistKeyCache = {

}

const persistConfig = {
    key: 'persist',
    storage: localforage,
    /*storage: {
        getItem: async key => {
            return new Promise(async (resolve) => {
                if(key === "persist:persist") {
                    const fileName = localStorage.getItem("currentPersistenceFile")

                    const opfs = await navigator.storage.getDirectory()
                    const entries = await opfs.entries()

                    for await(let other of entries) {
                        const key = other[0]
                        if(key.startsWith("persist_persist.blob") && key !== fileName) {
                            await opfs.removeEntry(key)
                        }
                    }

                    const file = await opfs.getFileHandle(fileName, { create: true })

                    const output = await file.getFile()

                    const result = await output.text()

                    let data = null

                    try {
                        data = JSON.parse(result)

                        Object.keys(data).forEach(key => {
                            data[key] = JSON.stringify(data[key])
                        })
                    } catch (e) {
                        console.log(e)
                        data = result
                    }

                    return resolve(JSON.stringify(data))
                } else return resolve()
            })
        },
        setItem: async (key, value) => {
            if(key === "persist:persist") {
                try {
                    await new Promise(async (resolve, reject) => {
                        //const fileName = localStorage.getItem("currentPersistenceFile")

                        //const opfs = await navigator.storage.getDirectory()
                        //const file = await opfs.getFileHandle(fileName, { create: true })

                        //const output = await file.getFile()

                        //const result = await output.text()
                        const newData = JSON.parse(value)

                        let isEmpty = true

                        Object.keys(newData).forEach(key => {
                            if(key.endsWith("Reducer")) {
                                const dataItem = JSON.parse(newData[key])
                                const arrayLength = dataItem[Object.keys(dataItem)[0]].length

                                if(arrayLength > 0) isEmpty = false
                            }
                        })

                        if(isEmpty/* && result.length !== 0) {
                            return reject("Cancelled persistence because of bad internet connection.")
                        }

                        return resolve()
                    })
                } catch(e) {
                    console.log(e)
                    return false
                }
            }

            if(persistKeyCache[key]) {
                clearTimeout(persistKeyCache[key])
            }

            persistKeyCache[key] = setTimeout(() => {
                const data = JSON.parse(value)

                let object = {}

                Object.keys(data).forEach(dataKey => {
                    object[dataKey] = JSON.parse(data[dataKey])
                })

                return new Promise(async (resolve, reject) => {
                    try {
                        const fileName = localStorage.getItem("currentPersistenceFile")

                        global.backupWorker.addEventListener("message", e => {
                            if(String(e.data).startsWith("done:")) {
                                const fileName = String(e.data).substring(5)

                                localStorage.setItem("currentPersistenceFile", fileName)

                                console.log(fileName)

                                return resolve()
                            }
                        })

                        global.backupWorker.postMessage({
                            key,
                            value: object
                        })

                        const opfs = await navigator.storage.getDirectory()
                        await opfs.removeEntry(fileName)
                    } catch(e) {
                        console.warn(e)
                        return reject(e)
                    }
                })
            }, 1000)

            return true
        },
        removeItem: async key => {
            const fileName = key.replace(/:/g, "_") + ".blob"
            const opfs = await navigator.storage.getDirectory()
            await opfs.removeEntry(fileName)
        }
    },*/
};

const extendedOfflineReduxConfig = {
    ...offlineConfig,
    discard: discardRequest,
    retry: retryRequest,
    queue: {
        ...defaultQueue,
        dequeue(array, item, context) {
            const [, ...rest] = array;
            if(rest.length === 0) {
                console.log("everything is dequeued, length is 0")
                //fetchInitData();
            }
            return rest;
        },
        // Stop peek when not exist jwt token
        peek(outbox, action, {offline}) {
            const jwtToken = getJwtToken();
            if (!jwtToken) {
                return false;
            } else {
                if(outbox.length === 0 && action.type === OFFLINE_STATUS_CHANGED && action.payload.online) {
                    console.log("offline status changed, outbox 0")
                        fetchInitData();
                } else {
                    // Update jwt token for every action from queue
                    const authHeader = getAuthHeader();
                    outbox.forEach(outboxAction => {
                        outboxAction.meta.offline.effect.headers = authHeader;
                    })
                }
            }

            return outbox[0];
        },
    },
    persist: false
}

const {
    middleware: offlineMiddleware,
    enhanceReducer: offlineEnhanceReducer,
    enhanceStore: offlineEnhanceStore
} = createOffline(extendedOfflineReduxConfig);

const persistedReducer = persistReducer(persistConfig, offlineEnhanceReducer(rootReducer));

export default () => {
    let store = createStore(persistedReducer,
        compose(
            offlineEnhanceStore,
            applyMiddleware(offlineMiddleware, thunkMiddleware)
    ));
    let persistor = persistStore(store);
    return {store, persistor}
}
