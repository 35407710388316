import {
    FETCH_PLANS_SUCCESS,
    PATCH_PLAN_SEND,
    PATCH_PLAN_SEND_COMMIT,
    PATCH_PLAN_SEND_ROLLBACK,
    POST_PLAN_SEND,
    DELETE_PLAN_ROLLBACK,
    DELETE_PLAN_COMMIT,
    DELETE_PLAN_SEND
} from "../actions/plan.action"

const initState = {
    plans: []
}

const planReducer = (state = initState, action) => {
    switch (action.type) {
        case FETCH_PLANS_SUCCESS:
            return {
                ...state,
                plans: action.payload
            }
        case PATCH_PLAN_SEND:
            return {
                plans: action.meta.offline.effect.meta.plans
            }
        case POST_PLAN_SEND:
            return {
                plans: action.meta.offline.effect.meta.plans
            }
        case PATCH_PLAN_SEND_COMMIT:
            return state
        case PATCH_PLAN_SEND_ROLLBACK:
            return state
        case DELETE_PLAN_SEND:
            return {
                plans: action.meta.offline.effect.meta.plans
            }
        case DELETE_PLAN_COMMIT:
            return state
        case DELETE_PLAN_ROLLBACK:
            return state
        default:
            return state
    }
}

export default planReducer
