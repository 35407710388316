import {FETCH_CUSTOMERS_SUCCESS} from "../actions/customers.action";

const initState = {
    customers: []
}

const customersReducer = (state = initState, action) => {
    switch (action.type) {
        case FETCH_CUSTOMERS_SUCCESS:
            return {
                customers: action.payload
            }
        default:
            return state
    }
}

export default customersReducer
