import {FETCH_SUBSIDIARIES_SUCCESS} from "../actions/subsidiaries.action";

const initState = {
    subsidiaries: []
}

const subsidiariesReducer = (state = initState, action) => {
    switch (action.type) {
        case FETCH_SUBSIDIARIES_SUCCESS:
            return {
                subsidiaries: action.payload
            }
        default:
            return state
    }
}

export default subsidiariesReducer
