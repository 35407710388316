import feathersApp from "../../feathers";
import getAuthHeader from "../../authentication/auth.header";
import {fetchWorkOrdersSuccess} from "../actions/workorder.action";
import {fetchFacilitiesSuccess} from "../actions/facility.action";
import {fetchPlansSuccess} from "../actions/plan.action";
import {fetchObjectsSuccess} from "../actions/objects.action";
import {fetchObjectComponentsSuccess} from "../actions/object.components.action";
import {fetchMaintenancesSuccess} from "../actions/maintenances.action";
import {fetchMaintenanceInspectionsSuccess} from "../actions/maintenance.inspections.action";
import {fetchMaintenanceImagesSuccess} from "../actions/maintenance.images.action";
import {fetchFlawsSuccess} from "../actions/flaws.action";
import {fetchFlawImagesSuccess} from "../actions/flaw.images.action";
import {fetchWorkSectionsSuccess} from "../actions/worksections.action";
import {fetchComponentsSuccess} from "../actions/components.action";
import {fetchSubComponentsSuccess} from "../actions/sub.components.action";
import {fetchComponentInspectionsSuccess} from "../actions/component.inspections.action";
import {fetchInspectionsSuccess} from "../actions/inspections.action";
import {fetchUsersSuccess} from "../actions/users.action";
import {fetchCustomersSuccess} from "../actions/customers.action";
import {fetchSubsidiariesSuccess} from "../actions/subsidiaries.action";
import {reduxStore} from "../../index";
import logout from "../../logout/logout";

/**
 * Init data used in case:
 * 1. After logged in and offline state is empty
 * 2. After dequeue last request from redux offline state
 * 3. After change mode from offline to online and redux offline state is empty
 */
export const fetchInitData = async () => {
    try {
        let customers = await feathersApp.service('api/customers')
            .find({
                headers: getAuthHeader()
            });
        reduxStore.dispatch(fetchCustomersSuccess(customers))

        let subsidiaries = await feathersApp.service('api/subsidiaries')
            .find({
                headers: getAuthHeader()
            });
        reduxStore.dispatch(fetchSubsidiariesSuccess(subsidiaries))

        let workOrders = await feathersApp.service('api/work-orders/')
            .find({
                headers: getAuthHeader()
            })
        reduxStore.dispatch(fetchWorkOrdersSuccess(workOrders));

        let flaws = await feathersApp.service('api/flaws/')
            .find({
                headers: getAuthHeader()
            })
        reduxStore.dispatch(fetchFlawsSuccess(flaws));

        let flawImages = await feathersApp.service('api/flaw-images/')
            .find({
                headers: getAuthHeader()
            })
        reduxStore.dispatch(fetchFlawImagesSuccess(flawImages))

        let maintenances = await feathersApp.service('api/maintenances/')
            .find({
                headers: getAuthHeader()
            })
        reduxStore.dispatch(fetchMaintenancesSuccess(maintenances));
        let maintenanceImages = await feathersApp.service('api/maintenance-images/')
            .find({
                headers: getAuthHeader()
            })
        reduxStore.dispatch(fetchMaintenanceImagesSuccess(maintenanceImages))

        let maintenanceInspections = await feathersApp.service('api/maintenance-inspections/')
            .find({
                headers: getAuthHeader()
            })
        reduxStore.dispatch(fetchMaintenanceInspectionsSuccess(maintenanceInspections))

        let facilities = await feathersApp.service('api/facilities/')
            .find({
                headers: getAuthHeader()
            })
        reduxStore.dispatch(fetchFacilitiesSuccess(facilities));

        let plans = await feathersApp.service('api/plans/')
            .find({
                headers: getAuthHeader()
            })
        reduxStore.dispatch(fetchPlansSuccess(plans));

        let objects = await feathersApp.service('api/objects/')
            .find({
                headers: getAuthHeader()
            })
        reduxStore.dispatch(fetchObjectsSuccess(objects));

        let objectComponents = await feathersApp.service('api/object-components/')
            .find({
                headers: getAuthHeader()
            })
        reduxStore.dispatch(fetchObjectComponentsSuccess(objectComponents));

        let workSections = await feathersApp.service('api/work-sections')
            .find({
                headers: getAuthHeader()
            });
        reduxStore.dispatch(fetchWorkSectionsSuccess(workSections));

        let components = await feathersApp.service('api/components')
            .find({
                headers: getAuthHeader()
            });
        reduxStore.dispatch(fetchComponentsSuccess(components));

        let subComponents = await feathersApp.service('api/sub-components')
            .find({
                headers: getAuthHeader()
            });
        reduxStore.dispatch(fetchSubComponentsSuccess(subComponents));

        let users = await feathersApp.service('api/users')
            .find({
                headers: getAuthHeader()
            });
        reduxStore.dispatch(fetchUsersSuccess(users));

        let inspections = await feathersApp.service('api/inspections')
            .find({
                headers: getAuthHeader()
            });
        reduxStore.dispatch(fetchInspectionsSuccess(inspections));

        let componentInspections = await feathersApp.service('api/component-inspections')
            .find({
                headers: getAuthHeader()
            });
        reduxStore.dispatch(fetchComponentInspectionsSuccess(componentInspections));

        plans.forEach(plan => {
            return fetch(`${process.env.REACT_APP_FEATHERS_REST_CLIENT}/resources/plan-image/${plan.id}`, {
                headers: getAuthHeader()
            }).then(result => result.text()).then(async result => {
                console.log("Plan image for plan " + plan.id + " saved to OPFS.")
                try {
                    const opfsRoot = await navigator.storage.getDirectory();
                    const fileHandle = await opfsRoot.getFileHandle("plan-image." + plan.id, {
                        create: true,
                    });
                    const writable = await fileHandle.createWritable();
                    await writable.write(result);
// Close the stream, which persists the contents.
                    await writable.close();
                } catch(e) {
                    console.log(e)
                }
            }).catch(e => console.log(e))
        })
    } catch (reason) {
        console.log(reason)
        if(reason.code === 401) {
            logout()
        }
        /*const msg = reason.code === 401 ? "Your token is invalid. You will be logged out."
            : "Cannot fetch required data from server. You will be logged out.";
        //window.alert(msg);
        //logout();*/
    }
}
