import logout from "../../logout/logout";

export function discardRequest(error, action) {
    console.error(error)
    console.log(action)

    // network issues, retry after 1s, 5s, 15s... 1 week
    if (!error.status) {
        return false;
    }

    if(error.status === 400 && action.type.startsWith("PATCH_")) return true

    // http 5xx errors, retry after 1s, 5s, 15s... 1 week
    else if (error.status >= 500) {
        if(error.response.message.includes("Empty .update() call detected!")) return true
        return false;
    }
    else if (error.status === 400) {
        if(error.response.message.includes("duplicate key value violates unique constraint")) return true
        return false;
    }
    else if (error.status === 404) {
        return false;
    }
    else if (error.status === 422) {
        return false;
    }
    // http 401 error, retry after log in
    /**
     * Retry steps:
     * 1. Remove jwt token
     * 2. Stop peek request from redux offline queue
     * 3. After logged in start sending request form the queue
     * 4. Refresh dashboard
     */
    else if (error.status === 401) {
        const msg = "Your token is invalid. You will be logged out.";
        window.alert(msg);
        logout();
        return false
    }

    //http 4xx errors, discard
    else {
        return true;
    }
}
