import getAuthHeader from "../../authentication/auth.header"
import {SERVER_URL} from "./const.action"

export const FETCH_PLANS_SUCCESS = 'FETCH_PLANS_SUCCESS'
export const PATCH_PLAN_SEND = 'PATCH_PLAN_SEND'
export const PATCH_PLAN_SEND_COMMIT = 'PATCH_PLAN_SEND_COMMIT'
export const PATCH_PLAN_SEND_ROLLBACK = 'PATCH_PLAN_SEND_ROLLBACK'
export const POST_PLAN_SEND = 'POST_PLAN_SEND'
export const POST_PLAN_COMMIT = 'POST_PLAN_COMMIT'
export const POST_PLAN_ROLLBACK = 'POST_PLAN_ROLLBACK'
export const DELETE_PLAN_SEND = 'DELETE_PLAN_SEND'
export const DELETE_PLAN_COMMIT = 'DELETE_PLAN_COMMIT'
export const DELETE_PLAN_ROLLBACK = 'DELETE_PLAN_ROLLBACK'

export const fetchPlansSuccess = plans => {
    return {
        type: FETCH_PLANS_SUCCESS,
        payload: plans
    }
}

export const postPlan = (objectToPost, plans, imgSrc) => {
    localStorage.setItem("plan-image." + objectToPost.id, imgSrc)

    return {
        type: POST_PLAN_SEND,
        meta: {
            offline: {
                // the network action to execute:
                effect: {
                    url: SERVER_URL + '/api/plans/',
                    method: 'POST',
                    headers: getAuthHeader(),
                    json: {...objectToPost, image_data: imgSrc},
                    meta: {plans}
                },
                // action to dispatch when effect succeeds:
                commit: {
                    type: POST_PLAN_COMMIT
                },
                // action to dispatch if network action fails permanently:
                rollback: {
                    type: POST_PLAN_ROLLBACK
                }
            }
        }
    }
}

export const patchPlan = (planId, objectToPatch, plans) => {
    return {
        type: PATCH_PLAN_SEND,
        meta: {
            offline: {
                // the network action to execute:
                effect: {
                    url: SERVER_URL + '/api/plans/' + planId,
                    method: 'PATCH',
                    headers: getAuthHeader(),
                    json: objectToPatch,
                    meta: {plans}
                },
                // action to dispatch when effect succeeds:
                commit: {
                    type: PATCH_PLAN_SEND_COMMIT
                },
                // action to dispatch if network action fails permanently:
                rollback: {
                    type: PATCH_PLAN_SEND_ROLLBACK
                }
            }
        }
    }
}

export const deletePlans = (planId, plans) => {
    return {
        type: DELETE_PLAN_SEND,
        meta: {
            offline: {
                // the network action to execute:
                effect: {
                    url: SERVER_URL + '/api/plans/' + planId,
                    method: 'DELETE',
                    headers: getAuthHeader(),
                    meta: {plans}
                },
                // action to dispatch when effect succeeds:
                commit: {
                    type: DELETE_PLAN_COMMIT
                },
                // action to dispatch if network action fails permanently:
                rollback: {
                    type: DELETE_PLAN_ROLLBACK
                }
            }
        }
    }
}

