import getAuthHeader from "../../authentication/auth.header"
import {SERVER_URL} from "./const.action"

export const FETCH_FACILITIES_SUCCESS = 'FETCH_FACILITY_SUCCESS'
export const PATCH_FACILITY_SEND = 'PATCH_FACILITY_SEND'
export const PATCH_FACILITY_SEND_COMMIT = 'PATCH_FACILITY_SEND_COMMIT'
export const PATCH_FACILITY_SEND_ROLLBACK = 'PATCH_FACILITY_SEND_ROLLBACK'
export const POST_FACILITY_SEND = 'POST_FACILITY_SEND'
export const POST_FACILITY_COMMIT = 'POST_FACILITY_COMMIT'
export const POST_FACILITY_ROLLBACK = 'POST_FACILITY_ROLLBACK'
export const DELETE_FACILITIES_SEND = 'DELETE_FACILITIES_SEND'
export const DELETE_FACILITIES_COMMIT = 'DELETE_FACILITIES_COMMIT'
export const DELETE_FACILITIES_ROLLBACK = 'DELETE_FACILITIES_ROLLBACK'

export const fetchFacilitiesSuccess = facilities => {
    return {
        type: FETCH_FACILITIES_SUCCESS,
        payload: facilities
    }
}

export const postFacility = (objectToPost, facilities) => {
    return {
        type: POST_FACILITY_SEND,
        meta: {
            offline: {
                // the network action to execute:
                effect: {
                    url: SERVER_URL + '/api/facilities/',
                    method: 'POST',
                    headers: getAuthHeader(),
                    json: objectToPost,
                    meta: {facilities}
                },
                // action to dispatch when effect succeeds:
                commit: {
                    type: POST_FACILITY_COMMIT
                },
                // action to dispatch if network action fails permanently:
                rollback: {
                    type: POST_FACILITY_ROLLBACK
                }
            }
        }
    }
}

export const patchFacility = (facilityId, objectToPatch, facilities) => {
    return {
        type: PATCH_FACILITY_SEND,
        meta: {
            offline: {
                // the network action to execute:
                effect: {
                    url: SERVER_URL + '/api/facilities/' + facilityId,
                    method: 'PATCH',
                    headers: getAuthHeader(),
                    json: objectToPatch,
                    meta: {facilities}
                },
                // action to dispatch when effect succeeds:
                commit: {
                    type: PATCH_FACILITY_SEND_COMMIT
                },
                // action to dispatch if network action fails permanently:
                rollback: {
                    type: PATCH_FACILITY_SEND_ROLLBACK
                }
            }
        }
    }
}

export const deleteFacilities = (facilityId, facilities) => {
    return {
        type: DELETE_FACILITIES_SEND,
        meta: {
            offline: {
                // the network action to execute:
                effect: {
                    url: SERVER_URL + '/api/facilities/' + facilityId,
                    method: 'DELETE',
                    headers: getAuthHeader(),
                    meta: {facilities}
                },
                // action to dispatch when effect succeeds:
                commit: {
                    type: DELETE_FACILITIES_COMMIT
                },
                // action to dispatch if network action fails permanently:
                rollback: {
                    type: DELETE_FACILITIES_ROLLBACK
                }
            }
        }
    }
}

