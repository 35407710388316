import {fetchInitData} from "./init.data";

let interval;

const TIMEOUT_1_HOUR = 10 * 1000;
export const fetchInitDataTimer = (skipFirstRun = false) => {
    /*if(skipFirstRun) {
        setTimeout(invokeFetchInitData, TIMEOUT_1_HOUR);
    } else {
        invokeFetchInitData();
    }*/
}

export const stopFetchInitDataTimer = () => {
    /*if (interval) {
        clearInterval(interval);
    }*/
}

function invokeFetchInitData() {
    console.log("FETCH INIT DATA")
    stopFetchInitDataTimer();
    fetchInitData().then(() => {
        interval = setInterval(fetchInitData, TIMEOUT_1_HOUR);
    });
}
