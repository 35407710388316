import {FETCH_SUB_COMPONENTS_SUCCESS} from "../actions/sub.components.action";

const initState = {
    subComponents: []
}

const subComponentsReducer = (state = initState, action) => {
    switch (action.type) {
        case FETCH_SUB_COMPONENTS_SUCCESS:
            return {
                subComponents: action.payload
            }
        default:
            return state
    }
}

export default subComponentsReducer
