import {SERVER_URL} from "./const.action"

import getAuthHeader from "../../authentication/auth.header"

export const FETCH_OBJECT_COMPONENTS_SUCCESS = 'FETCH_OBJECT_COMPONENTS_SUCCESS'
export const POST_OBJECT_COMPONENT_SEND = 'POST_OBJECT_COMPONENT_SEND'
export const POST_OBJECT_COMPONENT_COMMIT = 'POST_OBJECT_COMPONENT_COMMIT'
export const POST_OBJECT_COMPONENT_ROLLBACK = 'POST_OBJECT_COMPONENT_ROLLBACK'
export const PATCH_OBJECT_COMPONENT_SEND = 'PATCH_OBJECT_COMPONENT_SEND'
export const PATCH_OBJECT_COMPONENT_SEND_COMMIT = 'PATCH_OBJECT_COMPONENT_SEND_COMMIT'
export const PATCH_OBJECT_COMPONENT_SEND_ROLLBACK = 'PATCH_OBJECT_COMPONENT_SEND_ROLLBACK'
export const DELETE_OBJECT_COMPONENTS_SEND = 'DELETE_OBJECT_COMPONENTS_SEND'
export const DELETE_OBJECT_COMPONENTS_COMMIT = 'DELETE_OBJECT_COMPONENTS_COMMIT'
export const DELETE_OBJECT_COMPONENTS_ROLLBACK = 'DELETE_OBJECT_COMPONENTS_ROLLBACK'

export const fetchObjectComponentsSuccess = objectComponents => {
    return {
        type: FETCH_OBJECT_COMPONENTS_SUCCESS,
        payload: objectComponents
    }
}

export const postObjectComponent = (objectToPost, objectComponents) => {
    return {
        type: POST_OBJECT_COMPONENT_SEND,
        meta: {
            offline: {
                // the network action to execute:
                effect: {
                    url: SERVER_URL + '/api/object-components/',
                    method: 'POST',
                    headers: getAuthHeader(),
                    json: objectToPost,
                    meta: {objectComponents}
                },
                // action to dispatch when effect succeeds:
                commit: {
                    type: POST_OBJECT_COMPONENT_COMMIT
                },
                // action to dispatch if network action fails permanently:
                rollback: {
                    type: POST_OBJECT_COMPONENT_ROLLBACK
                }
            }
        }
    }
}

export const patchObjectComponent = (objectComponentId, objectToPatch, objectComponents) => {
    return {
        type: PATCH_OBJECT_COMPONENT_SEND,
        meta: {
            offline: {
                // the network action to execute:
                effect: {
                    url: SERVER_URL + '/api/object-components/' + objectComponentId,
                    method: 'PATCH',
                    headers: getAuthHeader(),
                    json: objectToPatch,
                    meta: {objectComponents}
                },
                // action to dispatch when effect succeeds:
                commit: {
                    type: PATCH_OBJECT_COMPONENT_SEND_COMMIT
                },
                // action to dispatch if network action fails permanently:
                rollback: {
                    type: PATCH_OBJECT_COMPONENT_SEND_ROLLBACK
                }
            }
        }
    }
}

export const deleteObjectComponents = (objectComponentId, objectComponents) => {
    return {
        type: DELETE_OBJECT_COMPONENTS_SEND,
        meta: {
            offline: {
                // the network action to execute:
                effect: {
                    url: SERVER_URL + '/api/object-components/' + objectComponentId,
                    method: 'DELETE',
                    headers: getAuthHeader(),
                    meta: {objectComponents}
                },
                // action to dispatch when effect succeeds:
                commit: {
                    type: DELETE_OBJECT_COMPONENTS_COMMIT
                },
                // action to dispatch if network action fails permanently:
                rollback: {
                    type: DELETE_OBJECT_COMPONENTS_ROLLBACK
                }
            }
        }
    }
}

