import {FETCH_COMPONENTS_SUCCESS} from "../actions/components.action";

const initState = {
    components: []
}


const componentsReducer = (state = initState, action) => {
    switch (action.type) {
        case FETCH_COMPONENTS_SUCCESS:
            return {
                components: action.payload
            }
        default:
            return state
    }
}

export default componentsReducer