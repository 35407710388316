const schedule = [1000, //After 1 seconds
    1000 * 5, //After 5 seconds
    1000 * 15, //After 15 seconds
    1000 * 30, //After 30 seconds
    1000 * 60, //After 1 minute
    1000 * 60 * 3, //After 3 minutes
    1000 * 60 * 5, //After 5 minutes
    1000 * 60 * 10, //After 10 minutes
    1000 * 60 * 30, //After 30 minutes
    1000 * 60 * 60, //After 1 hour
    1000 * 60 * 60 * 3, //After 3 hours
    1000 * 60 * 60 * 5, //After 5 hours
    1000 * 60 * 60 * 10, //After 10 hours
    1000 * 60 * 60 * 16, //After 16 hours
    1000 * 60 * 60 * 24, //After 1 day
    1000 * 60 * 60 * 24 * 2, //After 2 days
    1000 * 60 * 60 * 24 * 3, //After 3 days
    1000 * 60 * 60 * 24 * 4, //After 4 days
    1000 * 60 * 60 * 24 * 5, //After 5 days
    1000 * 60 * 60 * 24 * 6, //After 6 days
    1000 * 60 * 60 * 24 * 7, //After 1 week
    1000 * 60 * 60 * 24 * 8, //After 8 days
    1000 * 60 * 60 * 24 * 9, //After 9 days
    1000 * 60 * 60 * 24 * 10, //After 10 days
    1000 * 60 * 60 * 24 * 11, //After 11 days
    1000 * 60 * 60 * 24 * 12, //After 12 days
    1000 * 60 * 60 * 24 * 13, //After 13 days
    1000 * 60 * 60 * 24 * 14, //After 2 week
];

export function retryRequest(action, retries) {
    return schedule[retries] || null;
}
