import * as serviceWorker from './serviceWorker';

import { getActions as getSWActions } from './store/sw';
import {Provider} from 'react-redux';

import './global.css';
import {PersistGate} from "redux-persist/integration/react";
import configureStore from './redux/store/store'
import * as Sentry from "@sentry/react";
import { CaptureConsole } from "@sentry/integrations";

//global.backupWorker = new WorkerBuilder(Worker);
global.backupWorker = new Worker("/backup.worker.js");
/*
Sentry.init({
  dsn: "https://3d6d6f879a764070389b14364f94f9d8@o329736.ingest.sentry.io/4505906131304448",
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost"],
    }),
      new CaptureConsole(),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});
*/
const { store, persistor } = configureStore();
export const reduxStore = store;

if (!document.ie) { // check for ie
  Promise.all([
    import('react'),
    import('react-dom'),
    import('./App'),
  ]).then(([
    { default: React },
    { default: ReactDOM },
    { default: App },
  ]) => {
    ReactDOM.render(
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <App />
          </PersistGate>
        </Provider>,
      document.getElementById('root'),
    );
  });

  serviceWorker.register({
    onSuccess: () => getSWActions().handleSuccess(),
    onUpdate: reg => getSWActions().handleUpdate(reg),
  });
}
