import {reduxStore} from "../index";
import {userLogout} from "../redux/actions/users.action";
import history from "../history/history";
import {stopFetchInitDataTimer} from "../redux/store/init.data.timer";


/**
 * Logout used in case:
 * 1. After clicked logout button
 * 2. After fetched init data and receipt any error
 * 3. After sent request from queue and receipt http 401 error
 */
export default (historyLocal) => {
    stopFetchInitDataTimer();
    reduxStore.dispatch(userLogout());
    localStorage.removeItem("jwtToken");
    (historyLocal || history).push("/login");
}
