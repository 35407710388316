const feathers = require('@feathersjs/feathers');
const rest = require('@feathersjs/rest-client');

const feathersApp = feathers();

// Connect to a different URL
console.log("[feathers/index.js] REACT_APP_FEATHERS_REST_CLIENT: " + process.env.REACT_APP_FEATHERS_REST_CLIENT)
const restClient = rest(process.env.REACT_APP_FEATHERS_REST_CLIENT)

// Configure an AJAX library (see below) with that client
feathersApp.configure(restClient.fetch(window.fetch));

export default feathersApp;
