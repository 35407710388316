import getAuthHeader from "../../authentication/auth.header";
import {SERVER_URL} from "./const.action";

export const FETCH_FLAWS_SUCCESS = 'FETCH_FLAWS_SUCCESS';
export const PATCH_FLAW_SEND = 'PATCH_FLAW_SEND';
export const PATCH_FLAW_SEND_COMMIT = 'PATCH_FLAW_SEND_COMMIT';
export const PATCH_FLAW_SEND_ROLLBACK = 'PATCH_FLAW_SEND_ROLLBACK';
export const POST_FLAW_SEND = 'POST_FLAW_SEND';
export const POST_FLAW_COMMIT = 'POST_FLAW_COMMIT';
export const POST_FLAW_ROLLBACK = 'POST_FLAW_ROLLBACK';

export const fetchFlawsSuccess = flaws => {
    console.log("SUCCESS")
    console.log(flaws)
    return {
        type: FETCH_FLAWS_SUCCESS,
        payload: flaws
    }
}

export const postFlaw = (objectToPost, flaws) => {
    return {
        type: POST_FLAW_SEND,
        meta: {
            offline: {
                // the network action to execute:
                effect: {
                    url: SERVER_URL + '/api/flaws/',
                    method: 'POST',
                    headers: getAuthHeader(),
                    json: objectToPost,
                    meta: {flaws}
                },
                // action to dispatch when effect succeeds:
                commit: {
                    type: POST_FLAW_COMMIT
                },
                // action to dispatch if network action fails permanently:
                rollback: {
                    type: POST_FLAW_ROLLBACK
                }
            }
        }
    }
}

export const patchFlaw = (flawId, objectToPatch, flaws) => {
    return {
        type: PATCH_FLAW_SEND,
        meta: {
            offline: {
                // the network action to execute:
                effect: {
                    url: SERVER_URL + '/api/flaws/' + flawId,
                    method: 'PATCH',
                    headers: getAuthHeader(),
                    json: objectToPatch,
                    meta: {flaws}
                },
                // action to dispatch when effect succeeds:
                commit: {
                    type: PATCH_FLAW_SEND_COMMIT
                },
                // action to dispatch if network action fails permanently:
                rollback: {
                    type: PATCH_FLAW_SEND_ROLLBACK
                }
            }
        }
    }
}
