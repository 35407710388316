export const USER_LOGOUT = 'USER_LOGOUT';
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';

export const fetchUsersSuccess = users => {
    return {
        type: FETCH_USERS_SUCCESS,
        payload: users
    }
}

export const userLogout = () => {
    return {
        type: USER_LOGOUT
    }
}
