import {SERVER_URL} from "./const.action";
import getAuthHeader from "../../authentication/auth.header";

export const FETCH_MAINTENANCE_INSPECTIONS_SUCCESS = 'FETCH_MAINTENANCE_INSPECTIONS_SUCCESS';
export const POST_MAINTENANCE_INSPECTION_SEND = 'POST_MAINTENANCE_INSPECTION_SEND';
export const POST_MAINTENANCE_INSPECTION_COMMIT = 'POST_MAINTENANCE_INSPECTION_COMMIT';
export const POST_MAINTENANCE_INSPECTION_ROLLBACK = 'POST_MAINTENANCE_INSPECTION_ROLLBACK';
export const PATCH_MAINTENANCE_INSPECTION_SEND = 'PATCH_MAINTENANCE_INSPECTION_SEND';
export const PATCH_MAINTENANCE_INSPECTION_SEND_COMMIT = 'PATCH_MAINTENANCE_INSPECTION_SEND_COMMIT';
export const PATCH_MAINTENANCE_INSPECTION_SEND_ROLLBACK = 'PATCH_MAINTENANCE_INSPECTION_SEND_ROLLBACK';

export const fetchMaintenanceInspectionsSuccess = maintenanceInspections => {
    return {
        type: FETCH_MAINTENANCE_INSPECTIONS_SUCCESS,
        payload: maintenanceInspections
    }
}

export const patchMaintenanceInspection = (maintenanceInspectionId, objectToPatch, maintenanceInspections) => {
    return {
        type: PATCH_MAINTENANCE_INSPECTION_SEND,
        meta: {
            offline: {
                // the network action to execute:
                effect: {
                    url: SERVER_URL + '/api/maintenance-inspections/' + maintenanceInspectionId,
                    method: 'PATCH',
                    headers: getAuthHeader(),
                    json: objectToPatch,
                    meta: {maintenanceInspections}
                },
                // action to dispatch when effect succeeds:
                commit: {
                    type: PATCH_MAINTENANCE_INSPECTION_SEND_COMMIT
                },
                // action to dispatch if network action fails permanently:
                rollback: {
                    type: PATCH_MAINTENANCE_INSPECTION_SEND_ROLLBACK
                }
            }
        }
    }
}

export const postMaintenanceInspection = (objectToPost, maintenanceInspections) => {
    return {
        type: POST_MAINTENANCE_INSPECTION_SEND,
        meta: {
            offline: {
                // the network action to execute:
                effect: {
                    url: SERVER_URL + '/api/maintenance-inspections/',
                    method: 'POST',
                    headers: getAuthHeader(),
                    json: objectToPost,
                    meta: {maintenanceInspections}
                },
                // action to dispatch when effect succeeds:
                commit: {
                    type: POST_MAINTENANCE_INSPECTION_COMMIT
                },
                // action to dispatch if network action fails permanently:
                rollback: {
                    type: POST_MAINTENANCE_INSPECTION_ROLLBACK
                }
            }
        }
    }
}
