import {
    FETCH_MAINTENANCES_SUCCESS,
    PATCH_MAINTENANCES_SEND,
    PATCH_MAINTENANCES_SEND_COMMIT,
    PATCH_MAINTENANCES_SEND_ROLLBACK,
    POST_MAINTENANCE_SEND
} from "../actions/maintenances.action";

const initState = {
    maintenances: []
}

const maintenancesReducer = (state = initState, action) => {
    switch (action.type) {
        case FETCH_MAINTENANCES_SUCCESS:
            return {
                ...state,
                maintenances: action.payload
            }
        case PATCH_MAINTENANCES_SEND:
            return {
                maintenances: action.meta.offline.effect.meta.maintenances
            }
        case POST_MAINTENANCE_SEND:
            return {
                maintenances: action.meta.offline.effect.meta.maintenances
            }
        case PATCH_MAINTENANCES_SEND_COMMIT:
            return state
        case PATCH_MAINTENANCES_SEND_ROLLBACK:
            return state
        default:
            return state
    }
}

export default maintenancesReducer
