import {FETCH_USERS_SUCCESS} from "../actions/users.action";

const initState = {
    users: []
}

const usersReducer = (state = initState, action) => {
    switch (action.type) {
        case FETCH_USERS_SUCCESS:
            return {
                users: action.payload
            }
        default:
            return state
    }
}

export default usersReducer
