import {
    FETCH_FLAWS_SUCCESS,
    PATCH_FLAW_SEND,
    PATCH_FLAW_SEND_COMMIT,
    PATCH_FLAW_SEND_ROLLBACK,
    POST_FLAW_SEND
} from "../actions/flaws.action";

const initState = {
    flaws: []
}

const flawsReducer = (state = initState, action) => {
    switch (action.type) {
        case FETCH_FLAWS_SUCCESS:
            return {
                ...state,
                flaws: action.payload
            }
        case PATCH_FLAW_SEND:
            return {
                flaws: action.meta.offline.effect.meta.flaws
            }
        case POST_FLAW_SEND:
            return {
                flaws: action.meta.offline.effect.meta.flaws
            }
        case PATCH_FLAW_SEND_COMMIT:
            return state
        case PATCH_FLAW_SEND_ROLLBACK:
            return state
        default:
            return state
    }
}

export default flawsReducer
