import {
    FETCH_FACILITIES_SUCCESS,
    PATCH_FACILITY_SEND,
    PATCH_FACILITY_SEND_COMMIT,
    PATCH_FACILITY_SEND_ROLLBACK,
    POST_FACILITY_SEND,
    DELETE_FACILITIES_ROLLBACK,
    DELETE_FACILITIES_COMMIT,
    DELETE_FACILITIES_SEND
} from "../actions/facility.action"

const initState = {
    facilities: []
}

const facilityReducer = (state = initState, action) => {
    switch (action.type) {
        case FETCH_FACILITIES_SUCCESS:
            return {
                ...state,
                facilities: action.payload
            }
        case PATCH_FACILITY_SEND:
            return {
                facilities: action.meta.offline.effect.meta.facilities
            }
        case POST_FACILITY_SEND:
            return {
                facilities: action.meta.offline.effect.meta.facilities
            }
        case PATCH_FACILITY_SEND_COMMIT:
            return state
        case PATCH_FACILITY_SEND_ROLLBACK:
            return state
        case DELETE_FACILITIES_SEND:
            return {
                facilities: action.meta.offline.effect.meta.facilities
            }
        case DELETE_FACILITIES_COMMIT:
            return state
        case DELETE_FACILITIES_ROLLBACK:
            return state
        default:
            return state
    }
}

export default facilityReducer
