import {FETCH_INSPECTIONS_SUCCESS} from "../actions/inspections.action";

const initState = {
    inspections: []
}

const inspectionsReducer = (state = initState, action) => {
    switch (action.type) {
        case FETCH_INSPECTIONS_SUCCESS:
            return {
                inspections: action.payload
            }
        default:
            return state
    }
}

export default inspectionsReducer
