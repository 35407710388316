import {combineReducers} from 'redux'
import facilityReducer from './facility.reducer'
import planReducer from './plan.reducer'
import workOrdersReducer from "./workorders.reducer";
import objectsReducer from "./objects.reducer";
import objectComponentsReducer from "./object.components.reducer";
import maintenancesReducer from "./maintenances.reducer";
import maintenanceInspectionsReducer from "./maintenance.inspections.reducer";
import flawsReducer from "./flaws.reducer";
import workSectionsReducer from "./worksections.reducer";
import componentsReducer from "./components.reducer";
import subComponentsReducer from "./sub.components.reducer";
import componentInspectionsReducer from "./component.inspections.reducer";
import maintenanceImagesReducer from "./maintenance.images.reducer";
import flawImagesReducer from "./flaw.images.reducer";
import inspectionsReducer from "./inspections.reducer";
import usersReducer from "./users.reducer";
import customersReducer from "./customers.reducer";
import subsidiariesReducer from "./subsidiaries.reducer";
import {USER_LOGOUT} from "../actions/users.action";

const appReducer = combineReducers({
    facilityReducer, planReducer, workOrdersReducer, objectsReducer, objectComponentsReducer, maintenancesReducer,
    maintenanceInspectionsReducer, flawsReducer, workSectionsReducer, componentsReducer, maintenanceImagesReducer,
    flawImagesReducer, subComponentsReducer, componentInspectionsReducer, inspectionsReducer, usersReducer, customersReducer, subsidiariesReducer
})

const initialState = appReducer({}, {})

// clear all state except Redux Offline state (the queue with request to send)
export default (state, action) => {

    if (action.type === USER_LOGOUT) {
        state = initialState
    }

    return appReducer(state, action);
}
