import getAuthHeader from "../../authentication/auth.header";
import {SERVER_URL} from "./const.action";

export const FETCH_MAINTENANCE_IMAGES_SUCCESS = 'FETCH_MAINTENANCE_IMAGES_SUCCESS';
export const POST_MAINTENANCE_IMAGES_SEND = 'POST_MAINTENANCE_IMAGES_SEND';
export const POST_MAINTENANCE_IMAGES_COMMIT = 'POST_MAINTENANCE_IMAGES_COMMIT';
export const POST_MAINTENANCE_IMAGES_ROLLBACK = 'POST_MAINTENANCE_IMAGES_ROLLBACK';
export const DELETE_MAINTENANCE_IMAGES_SEND = 'DELETE_MAINTENANCE_IMAGES_SEND';
export const DELETE_MAINTENANCE_IMAGES_COMMIT = 'DELETE_MAINTENANCE_IMAGES_COMMIT';
export const DELETE_MAINTENANCE_IMAGES_ROLLBACK = 'DELETE_MAINTENANCE_IMAGES_ROLLBACK';

export const fetchMaintenanceImagesSuccess = maintenanceImages => {
    return {
        type: FETCH_MAINTENANCE_IMAGES_SUCCESS,
        payload: maintenanceImages
    }
}

export const postMaintenanceImages = (objectToPost, maintenanceImages, imgSrc) => {
    return {
        type: POST_MAINTENANCE_IMAGES_SEND,
        meta: {
            offline: {
                // the network action to execute:
                effect: {
                    url: SERVER_URL + '/api/maintenance-images/',
                    method: 'POST',
                    headers: getAuthHeader(),
                    json: {...objectToPost, image_data: imgSrc},
                    meta: {maintenanceImages}
                },
                // action to dispatch when effect succeeds:
                commit: {
                    type: POST_MAINTENANCE_IMAGES_COMMIT
                },
                // action to dispatch if network action fails permanently:
                rollback: {
                    type: POST_MAINTENANCE_IMAGES_ROLLBACK
                }
            }
        }
    }
}

export const deleteMaintenanceImages = (maintenanceImageId, maintenanceImages) => {
    return {
        type: DELETE_MAINTENANCE_IMAGES_SEND,
        meta: {
            offline: {
                // the network action to execute:
                effect: {
                    url: SERVER_URL + '/api/maintenance-images/' + maintenanceImageId,
                    method: 'DELETE',
                    headers: getAuthHeader(),
                    meta: {maintenanceImages}
                },
                // action to dispatch when effect succeeds:
                commit: {
                    type: DELETE_MAINTENANCE_IMAGES_COMMIT
                },
                // action to dispatch if network action fails permanently:
                rollback: {
                    type: DELETE_MAINTENANCE_IMAGES_ROLLBACK
                }
            }
        }
    }
}
