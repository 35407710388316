import {
    DELETE_MAINTENANCE_IMAGES_COMMIT,
    DELETE_MAINTENANCE_IMAGES_ROLLBACK,
    DELETE_MAINTENANCE_IMAGES_SEND,
    FETCH_MAINTENANCE_IMAGES_SUCCESS,
    POST_MAINTENANCE_IMAGES_COMMIT,
    POST_MAINTENANCE_IMAGES_ROLLBACK,
    POST_MAINTENANCE_IMAGES_SEND
} from "../actions/maintenance.images.action";

const initState = {
    maintenanceImages: [],
}

const maintenanceImagesReducer = (state = initState, action) => {
    switch (action.type) {
        case FETCH_MAINTENANCE_IMAGES_SUCCESS:
            return {
                ...state,
                maintenanceImages: action.payload
            }
        case POST_MAINTENANCE_IMAGES_SEND:
            return {
                maintenanceImages: action.meta.offline.effect.meta.maintenanceImages,
            }
        case POST_MAINTENANCE_IMAGES_COMMIT:
            return state
        case POST_MAINTENANCE_IMAGES_ROLLBACK:
            return state
        case DELETE_MAINTENANCE_IMAGES_SEND:
            return {
                maintenanceImages: action.meta.offline.effect.meta.maintenanceImages,
            }
        case DELETE_MAINTENANCE_IMAGES_COMMIT:
            return state
        case DELETE_MAINTENANCE_IMAGES_ROLLBACK:
            return state
        default:
            return state
    }
}

export default maintenanceImagesReducer