import {
    FETCH_MAINTENANCE_INSPECTIONS_SUCCESS,
    POST_MAINTENANCE_INSPECTION_SEND,
    PATCH_MAINTENANCE_INSPECTION_SEND
} from "../actions/maintenance.inspections.action";

const initState = {
    maintenanceInspections: []
}

const maintenanceInspectionsReducer = (state = initState, action) => {
    switch (action.type) {
        case FETCH_MAINTENANCE_INSPECTIONS_SUCCESS:
            return {
                ...state,
                maintenanceInspections: action.payload
            }
        case PATCH_MAINTENANCE_INSPECTION_SEND:
            return {
                maintenanceInspections: action.meta.offline.effect.meta.maintenanceInspections
            }
        case POST_MAINTENANCE_INSPECTION_SEND:
            return {
                maintenanceInspections: action.meta.offline.effect.meta.maintenanceInspections
            }
        default:
            return state
    }
}

export default maintenanceInspectionsReducer
