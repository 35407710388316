import {SERVER_URL} from "./const.action";
import getAuthHeader from "../../authentication/auth.header";

export const FETCH_WORK_ORDERS_SUCCESS = 'FETCH_WORK_ORDERS_SUCCESS';
export const PATCH_WORK_ORDER_SEND = 'PATCH_WORK_ORDER_SEND';
export const PATCH_WORK_ORDER_SEND_COMMIT = 'PATCH_WORK_ORDER_SEND_COMMIT';
export const PATCH_WORK_ORDER_SEND_ROLLBACK = 'PATCH_WORK_ORDER_SEND_ROLLBACK';

export const fetchWorkOrdersSuccess = workOrders => {
    return {
        type: FETCH_WORK_ORDERS_SUCCESS,
        payload: workOrders
    }
}

export const patchWorkOrder = (workOrderId, objectToPatch, workOrders) => {
    return {
        type: PATCH_WORK_ORDER_SEND,
        meta: {
            offline: {
                // the network action to execute:
                effect: {
                    url: SERVER_URL + '/api/work-orders/' + workOrderId,
                    method: 'PATCH',
                    headers: getAuthHeader(),
                    json: objectToPatch,
                    meta: {workOrders}
                },
                // action to dispatch when effect succeeds:
                commit: {
                    type: PATCH_WORK_ORDER_SEND_COMMIT
                },
                // action to dispatch if network action fails permanently:
                rollback: {
                    type: PATCH_WORK_ORDER_SEND_ROLLBACK
                }
            }
        }
    }
}