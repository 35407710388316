import {
    DELETE_FLAW_IMAGES_COMMIT,
    DELETE_FLAW_IMAGES_ROLLBACK,
    DELETE_FLAW_IMAGES_SEND,
    FETCH_FLAW_IMAGES_SUCCESS,
    POST_FLAW_IMAGES_COMMIT,
    POST_FLAW_IMAGES_ROLLBACK,
    POST_FLAW_IMAGES_SEND
} from "../actions/flaw.images.action";

const initState = {
    flawImages: [],
}

const flawImagesReducer = (state = initState, action) => {
    switch (action.type) {
        case FETCH_FLAW_IMAGES_SUCCESS:
            return {
                ...state,
                flawImages: action.payload
            }
        case POST_FLAW_IMAGES_SEND:
            return {
                flawImages: action.meta.offline.effect.meta.flawImages,
            }
        case POST_FLAW_IMAGES_COMMIT:
            return state
        case POST_FLAW_IMAGES_ROLLBACK:
            return state
        case DELETE_FLAW_IMAGES_SEND:
            return {
                flawImages: action.meta.offline.effect.meta.flawImages,
            }
        case DELETE_FLAW_IMAGES_COMMIT:
            return state
        case DELETE_FLAW_IMAGES_ROLLBACK:
            return state
        default:
            return state
    }
}

export default flawImagesReducer