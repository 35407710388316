import {FETCH_COMPONENT_INSPECTIONS_SUCCESS} from "../actions/component.inspections.action";

const initState = {
    componentInspections: []
}

const componentInspectionsReducer = (state = initState, action) => {
    switch (action.type) {
        case FETCH_COMPONENT_INSPECTIONS_SUCCESS:
            return {
                componentInspections: action.payload
            }
        default:
            return state
    }
}

export default componentInspectionsReducer
